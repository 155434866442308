const $ = jQuery;
$(document).ready(function () {
    $('.nav-toggle').on('click', function (e) {
        e.preventDefault();
        if ($(this).hasClass('close')) {
            $(this).removeClass('close');
            $('.nav').removeClass('open');
            $('header').removeClass('open');
        } else {
            $(this).addClass('close');
            $('.nav').addClass('open');
            $('header').addClass('open');
        }
    });
    $('.nav-close').on('click', function (e) {
        e.preventDefault();
        $('.nav-toggle').removeClass('close');
        $('.nav').removeClass('open');
        $('header').removeClass('open');
    });

    /**
     * =========
     * COOKIE CONSENT
     * =========
     */

    let $$cookieDisclaimer = document.querySelector('.js-cookie-disclaimer');

    if (!localStorage.getItem('cookieDisclaimer')) {
        $$cookieDisclaimer.classList.add('is-active');
    }

    $$cookieDisclaimer.querySelector('button').addEventListener('click', function () {
        localStorage.setItem('cookieDisclaimer', true);
        $$cookieDisclaimer.classList.remove('is-active');
    });
});